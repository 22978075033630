import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import logo from './images/logo.png';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <header>
        <div className="container">
            <div className="d-flex justify-content-center justify-content-md-start w-100 w-md-auto">
            <img src={logo} style={{width:70,height:70}}/>
            <div className="brand" data-text="$BTROLL">
                $BTROLL
            </div>
        </div>
            <nav class="d-none d-md-block">
                <ul>
                    <li><a href="#omg">OMG!</a></li>
                    <li><a href="#trollenomics">TROLLenomics</a></li>
                    <li><a href="#buy">Buy</a></li>
                </ul>
            </nav>
        </div>
    </header>
    <App />
    <footer>
        <div className="container">
        <div class="copyright"><strong>$BTROLL</strong> was created as a tribute to enthusiasts and holds no intrinsic value. Invest at your own risk, only with funds you're prepared to part with. The value of the token might increase or decrease, and we hold no accountability for its market performance.</div>
        <br/>
            $BTROLL 2024
        </div>
    </footer>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
