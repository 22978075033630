
import julius from './images/julius.png';
import problem from './images/problem2.png';
import trollolol from './images/trollolol.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


function App() {
  return (
    <div>
      <main id="omg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={julius} className="img-fluid" alt="$BTROLL"/>
            </div>
            <div className="col-md-6 text-center">
              <h1 className="font-headings">
                <span className="font-slanted font-shadow" data-text="OMG!" style={{fontSize:130}}>
                  OMG!
                </span>
              </h1>
              <h1 className="font-headings">
                <span className="font-slanted font-shadow" data-text="TROLL is on BASE">
                  TROLL is on BASE
                </span>
              </h1>
              <p className="text-readable">Fair Launch of <span className='text-selected'>$BTROLL</span> coming soon…<br/>
                <a target="_blank" rel="noreferrer" href="https://app.uniswap.org/explore/tokens/base/0x59d1D606BdDCDC70adB1B56223f2bf36d2Cce440" className="buy-button mt-5">
                  WUT?
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <div className="trollolol">
        <img alt="TROLLOLOLOL" src={trollolol} style={{
              width: '560px',
              maxWidth: '100%',
        }}/>
      </div>
      <section id="trollenomics">
        <img src={problem} className="problem" alt="PROBLEM?"/>
          <div className="container">
            <div className="wrapper">
              <h1 className="font-headings mb-3">
                <span className="font-slanted font-shadow" data-text="Trollenomics">
                  Trollenomics
                </span>
              </h1>
              <p className="text-center mt-0 mb-4 text-readable">
              Bye Bye Contract keys and LP’s :)
              </p>
              <div className="row">
                <div className="col-md-6">
                  <div className="item">
                    <h1 className="font-headings mb-0" style={{fontSize:40}}>
                      <span className="font-slanted font-shadow" data-text="Taxes">
                        Taxes
                      </span>
                    </h1>
                    <span className="font-slanted font-shadow" data-text="$0" style={{fontSize:70}}>
                      $0
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <h1 className="font-headings mb-0" style={{fontSize:40}}>
                      <span className="font-slanted font-shadow" data-text="Token Supply">
                        Token Supply
                      </span>
                    </h1>
                    <span className="font-slanted font-shadow" data-text="21B*"  style={{fontSize:70}}>
                      21B*
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-center mt-5 text-readable">
                * Billones
              </div>
            </div>
          </div>
      </section>
      {/* <div className="Uniswap">
        <SwapWidget
        tokenList={MY_TOKEN_LIST}
        defaultInputAmount={5}
        />
      </div> */}
      <div id="buy">
        <div className="container text-center" style={{fontSize:30}}>
          <div className="mb-2 mt-5">
            Load up teh meme cannons and fire at ur own will!!!
          </div>
          <div class="d-block d-md-flex justify-content-center align-items-start">
            <div className="address">
              <span>Uh-ficial Contract Address:</span>
              0x59d1D606BdDCDC70adB1B56223f2bf36d2Cce440
            </div>
            <a target="_blank" rel="noreferrer"  href="https://app.uniswap.org/explore/tokens/base/0x59d1D606BdDCDC70adB1B56223f2bf36d2Cce440" className="buy-button ms-2">
              BUY
            </a>
          </div>
        </div>
      </div>
      <section id="about">
        <div className="container">
          <h1 className="font-headings">
            <span className="font-slanted font-shadow" data-text="About this killer token">
              About this killer token
            </span>
          </h1>
          <div class="row mt-5 justify-content-center">
            <div class="col-md-6">
            So, liek, lemme tell ya 'bout this mad token called <span className='text-selected'>$BTROLL</span>, bruh.<br/>This ain't yer reg'lar crypto, nah. It's run by a squad of legit trolls, ya know?
            <br/>They ain't playin' around, man.
            <br/>They be droppin' the dankest meme coins, stirrin' up the internet like nobody's biz.
            <br/>If ya wanna roll with the real jesters of the crypto game,<br/><span className='text-selected'>$BTROLL's</span> where it's at, fam.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
